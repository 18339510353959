<template>
  <div class="main" data-title="营销分析" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">
          <span class="name">营销分析</span>
          <div class="page-search">
            <select-dep @setDeps="setDeps" ref="selectDep"></select-dep>
            <el-date-picker
              :clearable="false"
              @change="getNewData"
              end-placeholder="结束月份"
              format="yyyy年MM月"
              range-separator="-"
              start-placeholder="开始月份"
              style="margin-left:20px"
              type="monthrange"
              v-model="key"
              value-format="yyyy-MM"
            ></el-date-picker>
          </div>
        </h2>
      </div>
    </div>
    <el-card class="main-content" shadow="hover" v-if="!isData">
      <div class="no-data">暂无数据</div>
    </el-card>
    <div class="chart" v-else>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">各时间段来店客户情况</div>
            </div>
            <ve-bar :data="chartData.timeInterval" :legend-visible="false" :settings="chartSettings"></ve-bar>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">各时间段来店客户情况比</div>
            </div>
            <ve-pie :data="chartData.timeInterval" :legend-visible="false"></ve-pie>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">客户获知信息渠道来源数</div>
            </div>
            <ve-bar :data="chartData.influence" :legend-visible="false" :settings="chartSettings"></ve-bar>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">客户获知信息渠道来源数比</div>
            </div>
            <ve-pie :data="chartData.influence" :legend-visible="false"></ve-pie>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">客户关注车型数</div>
            </div>
            <ve-bar :data="chartData.demandCarType" :legend-visible="false" :settings="chartSettings"></ve-bar>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">客户关注车型数比</div>
            </div>
            <ve-pie :data="chartData.demandCarType" :legend-visible="false"></ve-pie>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">客户意向级别数</div>
            </div>
            <ve-bar :data="chartData.customerLevel" :legend-visible="false" :settings="chartSettings"></ve-bar>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">客户意向级别数比</div>
            </div>
            <ve-pie :data="chartData.customerLevel" :legend-visible="false"></ve-pie>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">旧车评估数</div>
            </div>
            <ve-bar :data="chartData.usedEvaluate" :legend-visible="false" :settings="chartSettings"></ve-bar>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">各区域客户来源数</div>
            </div>
            <ve-bar :data="chartData.customerArea" :legend-visible="false" :settings="chartSettings"></ve-bar>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="hover">
            <div class="jue-card-header">
              <div class="jue-card-title">各区域客户来源数比</div>
            </div>
            <ve-pie :data="chartData.customerArea" :legend-visible="false"></ve-pie>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { monthFormat } from '@/unit/unit.js'
import SelectDep from '@/components/chart/SelectDep.vue'
import VeBar from 'v-charts/lib/bar.common'
import VePie from 'v-charts/lib/pie.common'
export default {
  components: {
    SelectDep,
    VeBar,
    VePie,
  },
  data() {
    this.chartSettings = {
      labelMap: {
        'num': '数量',
      },
    }
    return {
      chartH: '300px',
      key: [monthFormat(), monthFormat()],
      dept_ids: [],
      listData: [],
      isData: false,

      chartData: {
        timeInterval: {
          columns: ['type', 'num'],
          rows: [],
        },
        influence: {
          columns: ['type', 'num'],
          rows: [],
        },
        customerArea: {
          columns: ['type', 'num'],
          rows: [],
        },
        demandCarType: {
          columns: ['type', 'num'],
          rows: [],
        },
        customerLevel: {
          columns: ['type', 'num'],
          rows: [],
        },
        usedEvaluate: {
          columns: ['type', 'num'],
          rows: [{ type: '旧车评估数', num: 0 }],
        },
      },
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    getNewData() {
      this.getListData()
    },
    setDeps(arr) {
      this.dept_ids = arr
      this.getListData()
    },
    getListData() {
      this.chartData.timeInterval.rows = []
      this.chartData.influence.rows = []
      this.chartData.customerArea.rows = []
      this.chartData.demandCarType.rows = []
      this.chartData.customerLevel.rows = []
      this.chartData.usedEvaluate.rows.num = 0

      let _this = this
      this.$axios
        .post('/statistic/data2', {
          begin_month: this.key[0],
          end_month: this.key[1],
          dept_ids: this.dept_ids,
        })
        .then((res) => {
          if (res.data.code == 200 && res.data.data.list.length) {
            this.isData = true
            //设置各时间来店情况
            this.initChartData(res, 'timeInterval')
            this.initChartData(res, 'influence')
            this.initChartData(res, 'customerArea')
            this.initChartData(res, 'demandCarType')
            this.initChartData(res, 'customerLevel')

            res.data.data.list.map((item, index) => {
              this.chartData.usedEvaluate.rows[0].num += item.usedEvaluate
            })
          } else {
            this.isData = false
          }
        })
    },

    initChartData(res, str) {
      let _this = this
      res.data.data.list.map((item, index) => {
        Object.keys(item[str]).map((k, v) => {
          if (index == 0) {
            _this.chartData[str].rows.push({
              type: this.getCfgData(k),
              num: item[str][k],
            })
          } else {
            let i = parseInt(_this.chartData[str].rows[v].num)
            _this.chartData[str].rows[v].num = i + parseInt(item[str][k])
          }
        })
      })
    },

    getCfgData(val) {
      if (val == '') {
        return '-'
      }
      let i = 0

      if (typeof val == 'string') {
        i = parseInt(val)
      } else {
        i = val
      }

      return this.$store.state.cfgData[i][0]
    },
  },
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
  overflow-x: auto;
}
.chart {
  padding: 20px;
  /deep/ .el-row {
    margin-bottom: 20px;
  }
}
</style>